<template>
  <div>
    <v-row>
      <v-col cols="3">
        <h3 class="mb-4">ТС в договоре</h3>
      </v-col>
      <v-spacer />

      <v-col cols="3">
        <v-autocomplete
          v-model="currentRoute"
          :items="routes"
          :loading="loadingRefs"
          item-text="name"
          item-value="id"
          label="Маршрут *"
          @change="changeRoute"
          class="mt-0 pt-0"
          return-object
          hide-details
        ></v-autocomplete>
      </v-col>

      <v-col cols="3">
        <v-autocomplete
          v-model="currentComposition"
          :items="compositions"
          :loading="loadingRefs"
          :item-text="compositionText"
          item-value="id"
          label="Состав *"
          :disabled="currentRoute === null"
          class="mt-0 pt-0"
          return-object
          clearable
          hide-details
        ></v-autocomplete>
      </v-col>

      <v-col>
        <v-btn
          color="primary"
          @click="addVehicle"
          :disabled="currentRoute === null"
        >
          Добавить
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      height="400"
      loading-text="Загрузка ТС"
      item-key="name"
      fixed-header
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.action="{ item }">
        <VehicleItem
          :item="item"
          :currentComposition="currentComposition.name"
          @onRemove="removeVehicle"
        ></VehicleItem>
      </template>
    </v-data-table>

    <v-row dense>
      <v-col class="text-right">
        <v-btn
          color="primary"
          @click="confirmAdd"
          :loading="loadingConfirm"
          :disabled="items.length === 0"
        >
          Применить
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import VehicleItem from "./VehicleItem";

  export default {
    name: "Vehicles",
    components: {
      VehicleItem,
    },
    props: {
      items: {
        type: Array,
        required: true,
      },
      routes: {
        type: Array,
        required: true,
      },
      compositions: {
        type: Array,
        required: true,
      },
      loadingRefs: {
        type: Boolean,
        required: true,
      },
      loadingConfirm: {
        type: Boolean,
        required: true,
      },
    },
    data: () => ({
      headers: [
        {
          text: 'ТС',
          value: 'govNum',
        },
        {
          text: 'Состав',
          value: 'compositionName',
        },
        {
          text: 'Маршрут',
          value: 'routeName',
        },
        {
          text: 'Дата начала действия',
          value: 'startDate',
        },
        {
          text: 'Дата окончания действия',
          value: 'endDate',
        },
        {
          text: '',
          value: 'action',
          sortable: false,
        },
      ],
      currentRoute: null,
      currentComposition: null,
      applyRoute: null,
    }),
    methods: {
      compositionText(item) {
        return item.name;
      },
      changeRoute() {
        this.currentcomposition = null;
      },
      addVehicle() {
        this.$emit('onAdd');
        this.$emit('composition', this.currentComposition);
        this.$emit('route', this.currentRoute)
      },
      removeVehicle(vehicle) {
        this.$emit('onRemove', vehicle);
      },
      confirmAdd() {
        this.$emit('onConfirm');
      }
    }
  }
</script>